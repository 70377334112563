import React, { Component } from 'react';
import './Header.css';
import API from '../classes/API.js';

import { NavLink } from 'react-router-dom';

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      gradeinfo_books: undefined,
      practice_folders: undefined,
    }

    this.mounted = false;

    this.toggle_active = this.toggle_active.bind(this);
    this.deactivate = this.deactivate.bind(this);
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  toggle_active() {
    this.setState({active: !this.state.active});
  }

  deactivate() {
    this.setState({active: false});
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getDocs()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState !== this.state) {
      if(this.state.active) {
        document.querySelector(".nav").className = "show nav"
      } else {
        document.querySelector(".nav").className = "nav"
      }

      if(this.state.practice_folders === undefined) {
        this.getDocs()
      }
    }
  }

  handleScroll = () => {
    if (window.scrollY > 20 && !this.state.active) {
      document.querySelector(".header").className = "header scroll";
    } else {
      document.querySelector(".header").className = "header";
    }
  };

  getDocs = () => {
    if(localStorage.getItem("user")) {
    let customers = JSON.parse(localStorage.getItem("user")).customers
    if(!customers) return

    let api = new API()
    api.post('api/find', {customers}, response => {
      if(response.gradeinfobooks) {
      this.setState({'gradeinfo_books': response.gradeinfobooks, 'practice_folders': 0})
      }
      else if(response.practicefolders) {
      this.setState({'practice_folders': response.practicefolders, "gradeinfo_books": 0})
      }
    })
    }
  }

  render() {

    var navItems = [];
    if(this.props.items && this.props.items.length > 0) {
      navItems.push(<NavLink exact activeClassName="nav-active" key="dashboard" to="/" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Dashboard</li>
            </NavLink>);

      if(this.props.items.indexOf('upcoming') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="upcoming" to="/upcoming" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Upcoming Lessons</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('previous') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="previous" to="/previous" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Previous Lessons</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('recording_and_reports') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="previous" to="/reports" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Lesson Recordings + Reports</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('report') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="report" to="/report" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Submit Report</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('practice_folders') >= 0) {
        for(let customer in this.state.practice_folders) {
          let customer_display_name = customer.split("$&^")[0]
          for(let key in this.state.practice_folders[customer]) {
            navItems.push(
              <a href={this.state.practice_folders[customer][key].substring(0, 4) === 'http' ?
                this.state.practice_folders[customer][key] :
                "https://"+this.state.practice_folders[customer][key]} className="nav--link clearfix" target="_blank" rel="noopener noreferrer" onClick={this.deactivate}>
              <li>{customer_display_name + "'s " + key + " Folder"}</li>
              </a>
            );
          }
        }
      }

      if(this.props.items.indexOf('student_gradesbooks') >= 0 && this.state.gradeinfo_books !== "") {
        let url = this.state.gradeinfo_books
        if(url) {
          navItems.push(
            <a href={url.substr(0, 4) === 'http' ? url : 'https://' + url} target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Grades Resources</li>
            </a>
          );
        } else {
          navItems.push(
            <a href="https://docs.google.com/spreadsheets/d/1TfEeEnp3cYQq47EWyeFo44gTRq5c4C_Ix26SUAm-2FY/edit#gid=1102362155" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Grade Resources</li>
            </a>
          );
        }
      }

      if(this.props.items.indexOf('parent_grade_syllabus') >= 0) {
        navItems.push(
          <a href="https://docs.google.com/spreadsheets/d/1ixybN9y-n5YWRKgv7ZVaRiXhyL_XMolqgJUaUesJOFk/edit#gid=1102362155" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
            <li>Grade Resources</li>
          </a>
        )
      }

      if(this.props.items.indexOf('tutor_blog') >= 0) {
        navItems.push(
          <a href="https://tutorblog.sammusicservice.com/" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
            <li>Tutor Blog</li>
          </a>
        );
      }

      if(this.props.items.indexOf('parent_blog') >= 0) {
        navItems.push(<a href="https://www.sammusicservice.com/parent-blog/" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Parent Blog</li>
            </a>);
      }

      if(this.props.items.indexOf('shop') >= 0) {
        navItems.push(<a key="shop" href="https://shop.ucanplay.org.uk/collections/sam" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Instrument Shop</li>
            </a>);
      }


      if(this.props.items.indexOf('GDPR') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="GDPR" to="/gdpr" className="nav--link clearfix" onClick={this.deactivate}>
              <li>GDPR</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('tutorial') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="tutorial" to="/tutorial" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Tutorial</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('payment') >= 0) {
        navItems.push(<a key="payment" href="https://secure.edirectdebit.co.uk/Sussex-Academy-of-Music-Ltd/Payments" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}><li>DD Setup</li></a>)
      }

      if(this.props.items.indexOf('invoicing') >= 0) {
        navItems.push(<NavLink activeClassName="nav-active" key="invoicing" to="/invoicing" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Invoicing</li>
            </NavLink>);
      }

      if(this.props.items.indexOf('promotions') >= 0) {
              navItems.push(<a key="promotions" href="https://www.sammusicservice.com/student-promotions/" className="nav--link clearfix" onClick={this.deactivate}>
                <li>Student Promotions</li>
                  </a>)
              }

      if(this.props.items.indexOf('resources_cust') >= 0) {
        navItems.push(<a key="resources_cust" href="https://drive.google.com/drive/folders/1Aq7ITiW9YoXmPZtQ9RQKsRooZYee-dWi" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Resources + Useful Info</li>
            </a>);
      }

      if(this.props.items.indexOf('resources_tutor') >= 0) {
        navItems.push(<a key="resouces_tutor" href="https://drive.google.com/drive/folders/18f1piZgVnCH7iZXoJb4tKW6B45itoXst" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Resources + Useful Info</li>
            </a>);
      }

      if(this.props.items.indexOf('resources_loc') >= 0) {
        navItems.push(<a key="resources_loc" href="https://drive.google.com/drive/folders/1zvGzR3jMwIY1DkMpFm7qtOSQqRPbHekm" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Useful Info</li>
            </a>);
      }

      if(this.props.items.indexOf('contact') >= 0) {
        navItems.push(<a href="https://www.sammusicservice.com/contact/" target="_blank" rel="noopener noreferrer" className="nav--link clearfix" onClick={this.deactivate}>
              <li>Contact</li>
            </a>);
      }

      navItems.push(<NavLink activeClassName="nav-active" key="changepassword" to="/changepassword" className="nav--link clearfix" onClick={this.deactivate}><li>Change Password</li></NavLink>)

      navItems.push(<NavLink activeClassName="nav-active" key="logout" to="/logout" className="nav--link clearfix" onClick={this.deactivate}><li>Logout</li></NavLink>)

    } else {
      console.log(this.props);
    }

    let hamburger = "";
    if(window.location.pathname !== '/login') {
      hamburger = (<div>
        <div className="burger-wrapper">
          <div className="hamburger" onClick={this.toggle_active}></div>
        </div>
        <ul className="nav">
          { navItems }
          </ul></div>)
    }

    return (
      <header className={this.state.active ? 'header menu-active': 'header'}>
        <a className="header-icon-link" href="/"><img alt="" className="header-icon" src="/images/modern_icons/SamLogoSVG2.svg" /></a>
          {hamburger}
      </header>
    );
  }
}

export default Header;
